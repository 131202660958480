import * as React from "react";
import AnimationButton from "../AnimationButton/AnimationButton";
import "./VarsitySection.scss";

const VarsitySection = ({ content }) => {
  return (
    <section className="varsity-container">
      <div>
        <img src={content.varsityImage} alt="School Leavers" className="desktop-only" />
      </div>
      <div>
        <h2>{content.varsityTitle}</h2>
        <p>{content.varsityDescription}</p>
        <AnimationButton route={content.route} label={content.label} />
      </div>
    </section>
  );
};

export default VarsitySection;
