import varsity from "../../assets/img/earlyCareersPageAssets/VarsityImage.png";

const earlyCareersUrl = "https://lifetimetraining.co.uk/personal/apprenticeship-vacancies/nandos/";

export const varsitySection = {
  varsityImage: varsity,
  route: earlyCareersUrl,
  label: "Apply Now",
  varsityTitle: "The School Leavers Programme – England only",
  varsityDescription:
    "Our School Leavers Programme is our Level 2 Apprenticeship that kickstarts our Nandocas (employees) careers. We are looking for school leavers and young people to join, firing them up for the future in work and life. You'll experience life in our restaurants, helping you to navigate the world of Hospitality, with training, support and coaching while you gain a nationally recognised qualification.",
};
